import {
  GLSelectMultipleOptions,
} from "@group-link-one/grouplink-components";
import { create } from "zustand";

export interface DeviceListStoreState {
  useCaseOptionsAvailables: GLSelectMultipleOptions[] | undefined
  useCaseOptionsSelecteds: GLSelectMultipleOptions[] | undefined
  userGroupSelecteds: GLSelectMultipleOptions[] | undefined
  deviceId: number | undefined
  modalType: "edit" | "register"

  modalDecomissionIsOpen: boolean
  isDecomissionLoading: boolean
}

export interface DeviceListStoreActions {
  setUseCaseOptions: (useCaseOptions: GLSelectMultipleOptions[] | undefined) => void;
  setUseCaseOptionsSelecteds: (useCaseOptionsSelecteds: GLSelectMultipleOptions[] | undefined) => void;
  setUserGroupSelecteds: (userGroupSelecteds: GLSelectMultipleOptions[] | undefined) => void;
  setDeviceId: (deviceId: number) => void;
  setModalType: (modalType: "edit" | "register") => void;

  setModalDecomissionIsOpen: (modalDecomissionIsOpen: boolean) => void;
  setIsDecomissionLoading: (isDecomissionLoading: boolean) => void;

  resetAll: () => void;
}

export interface DeviceListStore {
  state: DeviceListStoreState;
  actions: DeviceListStoreActions;
}

export const useDeviceListStore = create<DeviceListStore>((set) => ({
  state: {
    useCaseOptionsAvailables: undefined,
    useCaseOptionsSelecteds: undefined,
    userGroupSelecteds: undefined,
    deviceId: undefined,
    modalType: "edit",
    modalDecomissionIsOpen: false,
    isDecomissionLoading: false,
  },
  actions: {
    setUseCaseOptions: (useCaseOptionsAvailables) =>
      set((state) => ({
        state: {
          ...state.state,
          useCaseOptionsAvailables,
        }
      })),

    setUseCaseOptionsSelecteds: (useCaseOptionsSelecteds) =>
      set((state) => ({
        state: {
          ...state.state,
          useCaseOptionsSelecteds,
        }
      })),

    setUserGroupSelecteds: (userGroupSelecteds) =>
      set((state) => ({
        state: {
          ...state.state,
          userGroupSelecteds,
        }
      })),

    setDeviceId: (deviceId) =>
      set((state) => ({
        state: {
          ...state.state,
          deviceId,
        }
      })),

    setModalType: (modalType) =>
      set((state) => ({
        state: {
          ...state.state,
          modalType,
        }
      })),

    setModalDecomissionIsOpen: (modalDecomissionIsOpen) =>
      set((state) => ({
        state: {
          ...state.state,
          modalDecomissionIsOpen,

        }
      })),

    setIsDecomissionLoading: (isDecomissionLoading) =>
      set((state) => ({
        state: {
          ...state.state,
          isDecomissionLoading,
        }
      })),

    resetAll: () =>
      set(() => ({
        state: {
          useCaseOptionsAvailables: undefined,
          useCaseOptionsSelecteds: undefined,
          userGroupSelecteds: undefined,
          deviceId: undefined,
          modalType: "edit",
          modalDecomissionIsOpen: false,
          isDecomissionLoading: false,
        }
      })),
  },
}));
