import { useAuth } from "@group-link-one/gl-auth";
import { MeasurementCategory } from "@group-link-one/gl-auth/dist/auth/types/user";
import {
  BigNumberProps,
  Colors,
  GL_CHECK,
  GL_COLORS,
  GL_GAS,
  GL_GLStation,
  GL_INTERNET,
  GL_LOGIN,
  GL_THUNDERBOLT,
  GL_WATER,
  GLSelectMultipleOptions,
  i18n,
  useGLPagination,
  useI18n} from "@group-link-one/grouplink-components";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "usehooks-ts";

import { useFlags } from "../../Context/FlagsProvider";
import { usePrivileges } from "../../hooks/usePrivilleges";
import CheckImg from "../../images/DeviceList/icon-check.svg";
import InternetImg from "../../images/DeviceList/icon-internet.svg";
import LoginImg from "../../images/DeviceList/icon-log-in.svg";
import StationImg from "../../images/DeviceList/icon-station.svg";
import { useDeviceListService } from "../../Services/deviceListService/useDeviceListService";
import { BignumbersResponse } from "../../Services/deviceListService/useDeviceListService.types";
import { useDetailsDeviceStore } from "./store/details-device-store";
import { useDeviceListStore } from "./store/device-list-store";

export const useCasesMap = [
  {
    id: "gas",
    text: i18n.t("deviceList.selectUseCase.gas"),
    icon: <GL_GAS size={14} fill={Colors.ORANGE} />,
  },
  {
    id: "water",
    text: i18n.t("deviceList.selectUseCase.water"),
    icon: <GL_WATER size={14} fill={GL_COLORS.ACCENT_COLOR} />,
  },
  {
    id: "energy",
    text: i18n.t("deviceList.selectUseCase.energy"),
    icon: <GL_THUNDERBOLT size={14} fill={Colors.PURPLE} />,
  },
];

export const useDeviceListBody = () => {
  const [infoCounts, setInfoCounts] = useState({
    activated: 0,
    pending: 0,
    decommissioned: 0,
    totalDevices: 0,
  });

  const isSM = useMediaQuery("(max-width: 660px)");
  const { getBignumbers } = useDeviceListService();
  const { user, userGroups } = useAuth();
  const { t } = useI18n();
  const { state } = useDetailsDeviceStore();
  const { requests } = usePrivileges();
  const { hasBetaPrivileges } = useFlags();
  const { state: deviceListState, actions: deviceListActions } =
    useDeviceListStore();

  const { state: paginationState } = useGLPagination();

  const { data: bignumbersResponse, isLoading: bigNumbersIsLoading } = useQuery({
    queryKey: ["bignumbers-device-list"],
    queryFn: async () => {
      const response = await getBignumbers({
        org_id: user!.org.id!,
      });

      return response;
    },
  });

  const bigNumbers: BigNumberProps[] = useMemo(() => {
    return [
      {
        iconLeft: (
          <GL_GLStation
            fillOne={GL_COLORS.ULTRADARK_GREY_SECONDARY}
            fill={GL_COLORS.ULTRADARK_GREY_SECONDARY}
            size={24}
          />
        ),
        iconRight: <img src={StationImg} alt="" />,
        iconRightStyle: {
          bottom: -20,
          right: isSM ? -40 : 20,
          transform: isSM ? "scale(0.85)" : "none",
        },
        title: t("deviceList.bigNumbers.one.title"),
        count: infoCounts.activated,
        type: isSM ? "sm" : "lg",
        backgroundColor: Colors.LIGHT_GREEN,
        textColor: "ULTRADARK_GREY_SECONDARY",
        tooltipText: t("deviceList.bigNumbers.one.tooltipDescription"),
        tooltipPosition: "top-center",
        tooltipZIndex: 1,
        tooltipTextStyle: {
          whiteSpace: "nowrap",
        },
        isLoading: bigNumbersIsLoading,

      },
      {
        iconLeft: <GL_LOGIN fill={GL_COLORS.WHITE} size={24} />,
        iconRight: <img src={LoginImg} alt="" />,
        iconRightStyle: {
          bottom: -20,
          right: isSM ? -40 : 20,
          transform: isSM ? "scale(0.85)" : "none",
        },
        title: t("deviceList.bigNumbers.two.title"),
        count: infoCounts.pending,
        type: isSM ? "sm" : "lg",
        backgroundColor: Colors.NAVY,
        textColor: "WHITE",
        tooltipText: t("deviceList.bigNumbers.two.tooltipDescription"),
        helpIconColor: "WHITE",
        tooltipZIndex: 1,
        tooltipTextStyle: {
          whiteSpace: "nowrap",
        },
        isLoading: bigNumbersIsLoading,
      },
      {
        iconLeft: <GL_CHECK fill={GL_COLORS.WHITE} size={24} />,
        iconRight: <img src={CheckImg} alt="" />,
        iconRightStyle: {
          bottom: 0,
          right: isSM ? -40 : 20,
          transform: isSM ? "scale(0.85)" : "none",
        },
        title: t("deviceList.bigNumbers.three.title"),
        count: infoCounts.decommissioned,
        type: isSM ? "sm" : "lg",

        backgroundColor: GL_COLORS.ACCENT_COLOR,
        textColor: "WHITE",
        tooltipText: t("deviceList.bigNumbers.three.tooltipDescription"),
        helpIconColor: "WHITE",
        tooltipZIndex: 1,
        tooltipTextStyle: {
          whiteSpace: "nowrap",
        },
        isLoading: bigNumbersIsLoading,
      },
      {
        iconLeft: <GL_INTERNET fill={GL_COLORS.WHITE} size={24} />,
        iconRight: <img src={InternetImg} alt="" />,
        iconRightStyle: {
          bottom: 0,
          right: isSM ? -40 : 20,
          transform: isSM ? "scale(0.85)" : "none",
        },
        title: t("deviceList.bigNumbers.four.title"),
        count: infoCounts.totalDevices,
        type: isSM ? "sm" : "lg",
        backgroundColor: Colors.PURPLE,
        textColor: "WHITE",
        tooltipText: t("deviceList.bigNumbers.four.tooltipDescription"),
        helpIconColor: "WHITE",
        tooltipZIndex: 1,
        tooltipTextStyle: {
          whiteSpace: "nowrap",
        },
        isLoading: bigNumbersIsLoading,
      },
    ];//1847041125
  }, [infoCounts, isSM, bigNumbersIsLoading]);

  const userGroupsFormatted: GLSelectMultipleOptions[] | undefined = useMemo(() => {
    return userGroups && userGroups.map((group) => ({
      id: String(group.id),
      text: group.name,
    })) as GLSelectMultipleOptions[];
  }, [userGroups])

  function onSelectUseCase(useCaseOptionsSelecteds: GLSelectMultipleOptions[]) {
    deviceListActions.setUseCaseOptionsSelecteds(useCaseOptionsSelecteds);
  }

  function onSelectUserGroup(userGroupsSelecteds: GLSelectMultipleOptions[]) {
    deviceListActions.setUserGroupSelecteds(userGroupsSelecteds);
  }

  useEffect(() => {
    if (bignumbersResponse) {
      const activated = parseInt(
        bignumbersResponse.data.rows.find(
          (item: BignumbersResponse) => item.type === "activated_devices"
        )?.count || "0",
        10
      );
      const pending = parseInt(
        bignumbersResponse.data.rows.find(
          (item: BignumbersResponse) => item.type === "pending_devices"
        )?.count || "0",
        10
      );
      const decommissioned = parseInt(
        bignumbersResponse.data.rows.find(
          (item: BignumbersResponse) => item.type === "decommissioned_devices"
        )?.count || "0",
        10
      );

      const totalDevices = activated + pending + decommissioned;

      setInfoCounts({
        activated,
        pending,
        decommissioned,
        totalDevices,
      });
    }
  }, [bignumbersResponse]);

  useEffect(() => {
    deviceListActions.resetAll();

    const useCaseOptionsAvailables = useCasesMap.filter((useCase) =>
      user?.device_measurement_categories?.includes(
        useCase.id as MeasurementCategory
      )
    );

    if (
      useCaseOptionsAvailables.length > 1 &&
      paginationState.activeTabId === 1
    ) {
      deviceListActions.setUseCaseOptions(useCaseOptionsAvailables);
    } else {
      deviceListActions.setUseCaseOptionsSelecteds(undefined);
    }
  }, [user, paginationState.activeTabId]);

  return {
    deviceListState,
    deviceListActions,
    userGroupsFormatted,
    bigNumbers,
    requests,
    hasBetaPrivileges,
    isOpen: state.modalIsOpen,
    onSelectUseCase,
    onSelectUserGroup,
  };
};
